import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

const TagList = ({name, items}) => {
  return (
    <>
      {(name) ? <h3>{name}</h3> : ''}
      <ul className="actions small">
        {
          items.map(item => {
            let node = item
            if (item.node !== undefined) {
              node = item.node
            }
            return (
              <li key={node.id}><Link to={ node.path.alias } className="button fit">{node.name}</Link></li>
            )
          })
        }
      </ul>
    </>
  )
}

TagList.propTypes = {
  items: PropTypes.array.isRequired,
  name: PropTypes.string
}
export default TagList
