import React from "react"
import { graphql } from "gatsby"
import { useContext } from "react"
import Layout from "../components/layout"
import Img from 'gatsby-image'
import 'react-photoswipe/lib/photoswipe.css';
import {PhotoSwipe} from 'react-photoswipe';
import GalleryContext from '../contexts/gallery-context'
import TagList from '../components/tag-list'
import ArticleTeaser from '../components/article-teaser'
import normalize from "../utils/tools";
import SEO from "../components/seo"
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  RedditShareButton,
  TelegramShareButton,
  WhatsappShareButton,

  FacebookShareCount,

  TwitterShareButton,
  TwitterIcon,
  LinkedinIcon,
  FacebookIcon,
  RedditIcon,
  PinterestIcon,
  EmailIcon,
  TelegramIcon,
  WhatsappIcon
} from "react-share";

export default ({ data, location }) => {
  const post = data.nodeArticle

  const galleryContext = useContext(GalleryContext);
  let galleryItems = [];
  let galleryWrapper = '';
  if (data.nodeArticle.relationships.field_media.length > 0) {
    let galleryThumbnails = data.nodeArticle.relationships.field_media.map((image, index) => {
        if (image.relationships.field_media_image === null) {
          return ''
        }
        galleryItems.push({
          src: image.relationships.field_media_image.localFile.childImageSharp.original.src,
          w: image.relationships.field_media_image.localFile.childImageSharp.original.width,
          h: image.relationships.field_media_image.localFile.childImageSharp.original.height,
          title: image.relationships.field_media_image.relationships.media__image[0].field_media_image.alt
        })
        return <div className="col-2 col-3-large col-4-medium col-6-small" key={index}>
          <span className="image fit">
            <a href={'#image-gallery-' + index} id={'image-gallery-' + index} onClick={ () => {
              galleryContext.opened = true
              galleryContext.options.index = index
            }}>
              <Img fluid={ image.relationships.field_media_image.localFile.childImageSharp.fluid } />
            </a>
          </span>
        </div>
      }
    )
    galleryWrapper =(
      <div className="box alt">
        <div className="row gtr-50 gtr-uniform">
          {galleryThumbnails}
          <PhotoSwipe isOpen={galleryContext.opened} items={galleryItems} options={galleryContext.options} onClose={
            () => { galleryContext.opened = false }
          } />
        </div>
      </div>
    )
  }
  let article_body_elements = normalize(data.nodeArticle.body.processed, data.allFileFile)

  return (
    <Layout>
      <SEO title={post.title} description={post.body.summary} ogFB={post.relationships.field_cover.relationships.field_media_image.localFile.childImageSharp.ogFB.src} />
      <section>
        <div className="align-right">
          <small><em>Posted on <strong>{ new Date(post.created).toLocaleDateString('en-EN', { year: 'numeric', month: 'long', day: 'numeric' }) }</strong></em></small>
        </div>
        <header className="main">
          <h1>{ post.title }</h1>
        </header>
        <span className="image main">
          <Img fluid={ post.relationships.field_cover.relationships.field_media_image.localFile.childImageSharp.fluid } />
        </span>
        <div>
          {article_body_elements}
        </div>

        <div className="row">
          <div className="share-section col-12">

            <FacebookShareButton url={location.href}>
              <FacebookIcon size={32} round={true} />
              <FacebookShareCount url={location.href} />
            </FacebookShareButton>

            <TwitterShareButton url={location.href}>
              <TwitterIcon size={32} round={true} />
            </TwitterShareButton>

            <LinkedinShareButton url={location.href}>
              <LinkedinIcon size={32} round={true} />
            </LinkedinShareButton>

            <RedditShareButton url={location.href}>
              <RedditIcon size={32} round={true} />
            </RedditShareButton>

            <PinterestShareButton url={location.href} media={post.relationships.field_cover.relationships.field_media_image.localFile.childImageSharp.fixed.src}>
              <PinterestIcon size={32} round={true} />
            </PinterestShareButton>

            <TelegramShareButton url={location.href}>
              <TelegramIcon size={32} round={true} />
            </TelegramShareButton>

            <WhatsappShareButton url={location.href}>
              <WhatsappIcon size={32} round={true} />
            </WhatsappShareButton>

            <EmailShareButton url={location.href}>
              <EmailIcon size={32} round={true} />
            </EmailShareButton>
          </div>
        </div>

        {galleryWrapper}

        <div className="row">
          <div className="col-12 multiple">
            <TagList items={data.nodeArticle.relationships.field_tags} name="Tags & Categories"/>
            <TagList items={data.nodeArticle.relationships.field_categories} />
            <TagList items={data.nodeArticle.relationships.field_related_countries} />
          </div>
        </div>

        <section className="related-articles">
          <h2>Related Articles</h2>
          <div className="posts">
            {data.nodeArticle.relationships.field_related_articles.map(edge => {
                return <ArticleTeaser data={edge} key={edge.id}/>
              }
            )}
          </div>
        </section>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query($id: String!) {
    nodeArticle(id: { eq: $id }) {
      title
      body {
        summary
        processed
      }
      created
      relationships {
        field_cover {
          relationships {
            field_media_image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1200, maxHeight: 360, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                  fixed(width: 800, height: 1200, quality: 100) {
                    src
                  }
                  ogFB: fixed(width: 1200, height: 630, quality: 100, cropFocus: CENTER) {
                    ...GatsbyImageSharpFixed
                  } 
                }
              }
            }
          }
        }
        field_media {
          relationships {
            field_media_image {
              relationships {
                media__image {
                  field_media_image {
                    alt
                  }
                }
              }
              localFile {
                childImageSharp {
                  fluid(maxWidth: 300, maxHeight: 200, quality: 100, cropFocus: CENTER) {
                    ...GatsbyImageSharpFluid
                  }
                  original {
                    src
                    width
                    height
                  }
                }
              }
            }
          }
        }
        field_categories {
          name
          id
          path {
            alias
          }
        }
        field_tags {
          name
          id
          path {
            alias
          }
        }
        field_related_countries {
          name
          id
          path {
            alias
          }
        }
        field_related_articles {
          id,
          title
          body {
            summary
            value
          }
          created
          path {
            alias
          }
          relationships {
            field_cover {
              relationships {
                field_media_image {
                  localFile {
                    childImageSharp {
                      fixed(width: 480, height: 350, quality: 100, cropFocus: CENTER) {
                        ...GatsbyImageSharpFixed
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    allFileFile {
      edges {
        node {
        uri {
          url
        }
          localFile {
            childImageSharp {
              fluid(maxWidth: 1024) {
                ...GatsbyImageSharpFluid
                ...GatsbyImageSharpFluidLimitPresentationSize
              }
            }
          }
        }
      }
    }
  }
`